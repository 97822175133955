import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthHelper } from '@shared/helpers/authHelper.provider';

@Component({
  selector: 'app-signin-callback',
  template: `<div style="width: 100vw; height: 100vh;"></div>`
})

export class SigninRedirectCallbackComponent implements OnInit {
  constructor(private _authService: AuthHelper,
    private _router: Router) { }

  ngOnInit() {
    this._authService.completeLogin().then(user => {
      this._router.navigateByUrl((user.state as any).url ?? '/', { replaceUrl: true });
    });
  }
}
