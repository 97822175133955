import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthHelper } from '../shared/helpers/authHelper.provider';

@Component({
  selector: 'app-signout-callback',
  template: `<div style="width: 100vw; height: 100vh;"></div>`
})

export class SignoutRedirectCallbackComponent implements OnInit {
  constructor(private _authService: AuthHelper,
    private _router: Router) { }

  ngOnInit() {
    this._authService.completeLogout().then(_ => {
      this._router.navigate(['/'], { replaceUrl: true });
    });
  }
}
